import { Injectable } from '@angular/core';
import { BaseRequestService } from './base-request.service';
import { lastValueFrom, map } from 'rxjs';
import { AppFile } from '../models/bases/app-file';

@Injectable({
    providedIn: 'root',
})
export class FileService {
    constructor(readonly _baseRequestService: BaseRequestService) {}

    getFile(fileId: string) {
        return lastValueFrom<Blob>(
            this._baseRequestService
                .getFile(`files/${fileId}`, {
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }

    upload(file: File) {
        return lastValueFrom(
            this._baseRequestService
                .postFile<AppFile>(`files/upload`, {
                    data: {
                        file: file,
                    },
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }

    uploadFileWithOrg(file: File, organizationId?: string) {
        return lastValueFrom(
            this._baseRequestService
                .postFile<AppFile>(`files/upload`, {
                    data: {
                        file: file,
                        organization: organizationId,
                    },
                    is_loading: true,
                })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }

    deleteFile(fileId: string) {
        return lastValueFrom(
            this._baseRequestService
                .deleteJSON(`files/${fileId}`, { is_loading: true })
                .pipe(
                    map((res) => {
                        return res;
                    })
                )
        );
    }
}
